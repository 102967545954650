.footer_sec{
    
    margin-top: 25px;
}
.footer_inner{
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border-top: 1px solid #4a4a4a;
}
.footer_copyright{
    padding: 20px;
    border-top: 1px solid #4a4a4a;
    /* background: #1c1a1a; */
}
.f_l_l{
    color: #b9b9b9;
    text-decoration: none;
    font-size: 13px;
    padding: 0px 20px;
    transition: 300ms;
    border-right: 1px solid #0cfefe;
}
.f_l_l:hover{
    color: white;
    transition: 300ms;
}
.newletter_btn{
    border: 1px solid #0dffff;
    width: 51%;
    margin-bottom: 20px;
}
.newletter_btn input{
    padding: 11px;
    background: transparent;
    border-right: 1px solid #0dffff;
    /* border-radius: 5px; */
    font-size: 18px;
    color: #b3aeae;
    /* border: none; */
    border-bottom: 0px;
}
.newletter_btn button{
    padding: 14px;
    background: transparent;
    border: 1px solid whtie;
    /* border: 2px solid #0dffff; */
    font-size: 14px;
    color: white;
    border: none;
    cursor: pointer;
    transition: 200ms;

}
.newletter_btn button:hover{
    color: #0cfefe;
    transition: 200ms;
}

.social-links a {
	font-family: 'Noto Sans JP', sans-serif;
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255, 255, 255, 0.2);
	margin: 0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}

.social-links a:hover {
	color: #24262b;
	background-color: #ffffff;
}
.footer_links{
    display: flex;
    justify-content: space-between;
    width: 370px;
}
.footer_links div a{
    color: #878787;
    text-decoration: none;
}

.footer_links div a:hover{
    color: #2baee2;
    text-decoration: none;
}
