.tagBlue{
    border-radius: 4px;
    padding: 3px 7px;
    background: #2baee245;
    
    cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tagBlue span{
    font-size: 16px;
    padding: 0px 5px 0px 0px;
}
.tagGreen{
    border-radius: 4px;
    padding: 3px 7px;
    background: #3fe22b45;
   
    cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tagGreen span{
    font-size: 16px;
    padding: 0px 5px 0px 0px;
}
.tagGold{
    border-radius: 4px;
    padding: 3px 7px;
    background: #7a99008f;
   
    cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tagGold span{
    font-size: 16px;
    padding: 0px 5px 0px 0px;
}


.game_box{
    width: 375px;
    border-radius: 4px;
    padding: 5px;
    margin: 10px;
    box-shadow: 0px 0px 2px 0px #2baee2;
}
.game_box:hover{
 transition: .5s;
    box-shadow: 0px 0px 8px 0px #2baee2;
}
