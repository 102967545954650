.table_list_set{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 1200px;
}
.table_list_th{
    padding: 20px;
    text-align: start;
    background: #30c4ff1c;
    cursor: pointer;
    transition: 500ms;
    border-radius: 3px;
    border-bottom: 1px solid #ffffff;
    text-align: center;
}
.table_list_th:hover{
    border-bottom: 1px solid #49c6ff;
    transition: 500ms;
}
.table_list_set td{
    background: #6bd5ff17;
    padding: 15px;
    text-align: center;
    border-radius: 5px;

}