.inputLogin input,textarea{
    padding: 10px;
    background: transparent;
    border: 1px solid #2baee2;
    border-radius: 3px;
    width: 90%;
    margin: 10px 0px;
    color: white;
    font-size: 18px;
}
.inputLogin{
    display: flex;
    justify-content: center;
}
.btnLogin{
    width: 98%;
    background: #2baee2;
    border: none;
    border-radius: 3px;
    margin: 10px 0px;
    padding: 10px;
    cursor: pointer;
    color: white;
    font-size: 18px;
}
.btnLogin:hover{
    background-color: white;
    color:rgb(0, 0, 0);
    transition: 0.5s;
}
.atagLog{
    color: #2baee2;
}
.atagLog:hover{
    color: #ffffff;
}
.google_btn_w button{
    width: 100%;
}