.acc-top-cont{
    display: flex;
    justify-content: center;
}
.acc-cont1{
    width: 350px;
    box-shadow: 0px 0px 6px 0px white;
    border-radius: 7px;
    box-shadow: 0px 0px 6px 0px #23343a;
    padding: 20px;
    margin: 20px;
    background: #23343a99;
}
.acc-cont2{
    width: 729px;
    box-shadow: 0px 0px 6px 0px #23343a;
    border-radius: 7px;
    margin: 20px;
    padding: 20px;
    background: #23343a99;
}
.acc-count3{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.acc-logout{
    padding: 5px 10px;
    border-radius: 3px;
    border: none;
    background: #a3e5ff;
    color: black;
    font-size: 15px;
    cursor: pointer;
}

.acc-logout:hover{

    transition: 0.5s;
    background: #a3e5ff4a;
}