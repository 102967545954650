.tyc_controler{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.tyc_con{
    display: flex;
    background: #2a2a2a;
    margin: 20px;
    border-radius: 7px;
    align-items: center;
}
.tyc_divider{
    height: 60%;
    width: 2px;
    background: #606060;
    border-radius: 2px;
}

.tyc_default:hover{
    color: #818181;
    transition: 300ms;
}
.tyc_menu{
    font-size: 13px;
    padding: 5px;
    text-transform: uppercase;
    cursor: pointer;
}
.tyc_menu:hover{
    color: #818181;
    transition: 300ms;
}
.tyc_menu_sec{
    padding: 7px 20px;
}
.tyc_typer_cont{
    display: flex;
    justify-content: center;

}
.tyc_typer{
    display: flex;
    flex-wrap: wrap;
    width: 1150px;
    font-size: 20px;
}
.tyc_typer div{
    padding: 5px;
}

.ns_active{
    width: 2px;
    background-color: #0dffff;
    border-radius: 5px;
    
}
.s_active{
    width: 2px;
    background-color: white;
    border-radius: 5px;
    animation: typeblink 2s linear infinite;
}
@keyframes typeblink {
    0%{
        background-color: #0dffff;
    }50%{
        background-color: #0dffff00;
    }100%{
        background-color: #0dffff;
    }
    
}

.tyc_typer .w_active{
    padding: 5px 5px 5px 0px;

}

.tyc_reload{
    text-align: center;
    margin-top: 40px;
}
.tyc_reload span{
    font-size: 30px;
    color: #0dffff;
    cursor: pointer;
}
.tyc_reload span:hover{
    color: white;
    transition: 300ms;
}

.tyc_result{
    width: 200px;
    display: flex;
    justify-content: space-between;
    font-size: 28px;
    margin-bottom: 5px;
    border-bottom: 2px solid;
    padding: 5px 15px;
}
.tyc_res_sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
}
.tyc_result_more{
    width: 200px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}
.radio_signal_green{
    background: #15ff1c;
    border-radius: 50%;
    width: 10px;
    margin: 5px;
    height: 10px;
    opacity: 0.2;
    box-shadow: 0px 0px 4px 0px #15ff1c;
}
.radio_signal_red{
    background: #ff1515;
    border-radius: 50%;
    width: 10px;
    margin: 5px;
    height: 10px;
    opacity: 0.2;
    box-shadow: 0px 0px 4px 0px #ff1515;
}
.radio_signal_blue{
    background: #2baee2;
    border-radius: 50%;
    width: 10px;
    margin: 5px 5px 5px 0px;
    height: 10px;
    opacity: 1;
    box-shadow: 0px 0px 4px 0px #2baee2;
}
.resutl_firt_cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10px;
    box-shadow: 0px 0px 4px 0px white;
    border-radius: 4px;

}
.result_second_cont{
    width: 770px;
    /* border-left: 1px solid; */
    box-shadow: 0px 0px 4px 0px white;
    border-radius: 4px;
 
    padding-top: 40px;

}
.btn_result{
    background: transparent;
    border: none;
    box-shadow: 0px 0px 2px 0px white;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: .5s;
    margin: 10px;
}
.btn_result:hover{
    background-color: #2baee2;
    transition: .5s;
}
.btn_result span{
    color: white;
}
.sechighColor{
    color: #0cffff;
}