.notify_box{
    width: 900px;
    box-shadow: 0px 0px 3px 0px #2baee2;
    border-radius: 3px;
    padding: 10px;
    margin: 10px;
}
.notify_time{
    direction: rtl;
    color: #8fa3ab;
    font-size: 15px;
    opacity: 0;
}
.notify_coming{
    margin-top: -12px;
    margin-bottom: 16px;
    color: gray;
}