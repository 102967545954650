.home_top_star{
    stroke: #ffffff;
    fill: #0000001a;
    stroke-width: 0.5px;
    /* animation: typebanner 5s linear infinite; */
    stroke-dasharray: 125, 56;
    stroke-dashoffset: 150;
}
.home_top_star:hover{

    stroke-dashoffset: 60;
    transition: 800ms;
}

@keyframes typebanner {
    0%{
        stroke: #ffffff;
    }20%{
        stroke: #00d9ff;
    }40%{
        stroke: #15ff00;
    }60%{
        stroke: #b700ff;
    }80%{
        stroke: #ff0000;
    }100%{
        stroke: #ffffff;
    }
    
}
.home_top_btn{
    background: transparent;
    border: none;
    color: white;
    padding: 20px 60px;
    font-size: 23px;
    font-family: 'Material Symbols Outlined';
    box-shadow: 1px 1px 1px 1px;
    margin-top: 40px;
    cursor: pointer;
}
.home_top_btn:hover{
    background-color: #2baee21c;
    transition: 0.5s;
}

.home_top_cont_header{
    margin-top: -350px;
    /* margin-left: 83px; */
    margin-bottom: 70px;
    display: flex;
    justify-content: space-around;
}
.homt_top_header_img{
    width: 400px;
    margin-top: -84px;
    animation: typeimg 3s linear infinite;
}

@keyframes typeimg {
    0%{
        margin-top: -84px;
   
    }50%{
        margin-top: -70px;
   
    }100%{
        margin-top: -84px;
    }
    
}


.home_banner_card{
    width: 1200px;
    background: #00b7ff;
    border-radius: 10px;
}
.home_banner_card div{
    
    padding: 20px;
}
.home_botton_cont{
    display: flex;
    justify-content: center;
    margin: 50px 20px;
}

.home_botton_cont div button{
    padding: 5px 10px;
    border-radius: 3px;
    border: none;
    background: #a3e5ff;
    color: black;
    font-size: 15px;
    cursor: pointer;
}
.home_botton_cont div button:hover{
    transition: 0.5s;
    background: #a3e5ff4a;

}
.leader_board_head{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

}
.leader_board_head div{
    width: 90px;
    height: 3px;
    background: white;
    border-radius: 5px;
    margin-top: -14px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 20px 0px white;
}
.about_details{
    width: 100px;
    background: #3e3e3e;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
}



/* ///asdfsldkf */








/* 
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap'); */
.sethomeback {
    /* background: #4efff7; */
    background: linear-gradient(180deg, rgb(70 150 155 / 14%) 15%, rgb(52 91 93) 50%, rgb(69 147 152) 100%);
    /* background: linear-gradient(180deg, rgb(70 150 155) 15%, rgb(34 34 34) 50%, rgb(34 34 34) 100%); */
    /* background: linear-gradient(180deg, rgb(255 100 100) 15%, rgb(223 86 175) 50%, rgba(0, 212, 255, 1) 100%); */
    height: 700px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: -1;
	top: 0;
}
.wrapper span {
	z-index: -1;
    border: 2px solid white;
	position: absolute;
    top: 700px;
    height: 50px;
    width: 50px;
    background-color: #94fffe;
    box-shadow: 0 0 50px #48f2f7, 0 0 100px #4dfef7, 0 0 150px #4cfaf7, 0 0 200px #ff0000;
    animation: animate 10s linear infinite;
    border-radius: 5px;
}
.wrapper span:nth-child(1) {
	left: 60px;
	animation-delay: 0.6s;
}
.wrapper span:nth-child(2) {
	left: 10%;
	animation-delay: 3s;
	width: 60px;
	height: 60px;
}
.wrapper span:nth-child(3) {
	left: 20%;
	animation-delay: 2s;
}
.wrapper span:nth-child(4) {
	left: 30%;
	animation-delay: 5s;
	width: 80px;
	height: 80px;
}
.wrapper span:nth-child(5) {
	left: 40%;
	animation-delay: 1s;
}
.wrapper span:nth-child(6) {
	left: 50%;
	animation-delay: 7s;
}
.wrapper span:nth-child(7) {
	left: 60%;
	animation-delay: 6s;
	width: 100px;
	height: 100px;
}
.wrapper span:nth-child(8) {
	left: 70%;
	animation-delay: 8s;
}
.wrapper span:nth-child(9) {
	left: 80%;
	animation-delay: 6s;
	width: 90px;
	height: 90px;
}
.wrapper span:nth-child(10) {
	left: 90%;
	animation-delay: 4s;
}

@keyframes animate {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	80% {
		opacity: .7;
	}
	100% {
		transform: translateY(-800px) rotate(360deg);
		opacity: 0;
	}
}
.settopintro{
	margin-top: 250px;
	margin-bottom: 80px;
}
.set_a_b_name{
	text-align: center;
}

.education_line{
	font-family: 'Noto Sans JP', sans-serif;
	font-size: 40px;
    font-family: 'Kanit', sans-serif;
    width: 1050px;

}
.changewordstyle{
    background: linear-gradient(108deg,#46d3ff .14%,#6178ff 24.02%,#a750ff 50.05%,#ff784b 56.82%,#ffce51 85.45%);
    -webkit-background-clip: text;
    color: transparent;
}
.edcation_line_pervious{
	display: flex;
	justify-content: center;
}
.mybtn{
	padding: 8px 15px;
    font-size: 20px;
    border-radius: 4px;
    border: none;
    background: rgb(255 255 255 / 20%);
    margin: 10px;
	cursor: pointer;
	transition: 5ms;
  
    color: white;
}
.mybtn:hover{
   color: black;
	background-color: rgb(255, 255, 255);
	transition: 5ms;
}
.firstwave{
	transform:rotate(0deg); 
	transition: 0.3s
}
.secondwave{
	transform:translate(0, 0px); 
	opacity:1;
}

.marginwave{
	margin-top: 80px;
}

@media (max-width: 750px){
	.marginwave{
		margin-top: 20px;
	}
}
@media (max-width: 500px){
	.marginwave{
		margin-top: -45px;
	}
}
@media (max-width: 402px){

	.settopintro{
		margin-top: 115px;
		margin-bottom: 80px;
	}
	.marginwave{
		margin-top: -30px;
	}
	.set_a_b_name{
	    text-align: left;
    padding: 0px 10px;
    padding-top: 45px;
	}
	.setanipara{
		display: none;
	}

}















