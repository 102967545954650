::selection {
    color: rgb(255, 255, 255);
    background: rgb(0, 0, 0);
  }
  
  
  html {
    scroll-behavior: smooth;
  }
  
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(43, 174, 226);
  }